import { bindable, customElement, inject, NewInstance } from 'aurelia-framework';
import { HttpClient } from "aurelia-fetch-client";
import { AureliaConfiguration } from 'aurelia-configuration';
import { AuthInterceptor } from '../../auth/auth-interceptor';
import { LogRequestInterceptor } from '../../api/log-request-interceptor';
import { SentryRequestInterceptor } from '../../api/sentry-request-interceptor';


@customElement("sio-fetch-content")
@inject(NewInstance.of(HttpClient), AureliaConfiguration, AuthInterceptor, LogRequestInterceptor, SentryRequestInterceptor )
export class FetchContent {
    @bindable() object;
    @bindable() endpoint;
    @bindable() method = 'GET';
    @bindable() params = {};
    @bindable() headers = { 'Accept': '*/*' };
    @bindable() body = null;

    error = null;
    content = null;
    json = null;
    loading = false;

    constructor(httpClient, appConfig, interceptor, logInterceptor, sentryInterceptor) {
        this.client = httpClient;

        this.client.configure(config => config
            .useStandardConfiguration()
            .withBaseUrl(appConfig.get('apiUrl'))
            .withInterceptor(interceptor)
            .withInterceptor(sentryInterceptor)
            .withInterceptor(logInterceptor)
        );
    }

    async bind() {
        if (!this.endpoint) return;
        this.loading = true;
        try {
            let url = this.cleanUrl(this.endpoint);
            url = this.appendQueryParams(url, this.params);

            const options = {
                method: this.method.toUpperCase(),
                headers: this.prepareHeaders(),
                body: this.prepareBody()
            };

            const response = await this.client.fetch(url, options);

            console.log("Response received:", response);

            if (!response.ok) {
                if (response.status === 500) {
                    throw new Error("Server error (500): Something went wrong on the server.");
                }
                throw new Error(`HTTP Error: ${response.status} ${response.statusText}`);
            }

            await this.parseResponse(response);
            this.loading = false;
            this.error = null;
        } catch (err) {
            console.error("FetchContent Error:", err);

            if (err instanceof TypeError && err.message.includes("Failed to fetch")) {
                // Network error (e.g., no connection, or CORS issue)
                this.error = "Network error: Please check your connection.";
            } else if (err instanceof Error && err.message.includes("Server error (500)")) {
                // Server error (500)
                this.error = "Internal Server Error: Something went wrong on the server.";
            } else {
                // Generic unknown error
                this.error = err.message || "An unknown error occurred.";
            }

            // Add extra context to error handling to debug further
            console.error("Detailed error info:", {
                message: err.message,
                stack: err.stack,
                url: this.endpoint,
                method: this.method,
                params: this.params
            });

            this.content = null;
            this.json = null;
            this.loading = false;
        }
    }

    /** Removes leading slash from the endpoint */
    cleanUrl(url) {
        return url.startsWith('/') ? url.substring(1) : url;
    }

    /** Adds query parameters to the URL */
    appendQueryParams(url, params) {
        if (!params || Object.keys(params).length === 0) return url;

        let fullUrl = new URL(url, appConfig.get('apiUrl'));
        Object.keys(params).forEach(key => fullUrl.searchParams.append(key, String(params[key])));

        return fullUrl.toString();
    }

    /** Prepares headers */
    prepareHeaders() {
        return { 'Content-Type': 'application/json', ...this.headers };
    }

    /** Prepares body for non-GET methods */
    prepareBody() {
        return this.body && this.method.toUpperCase() !== 'GET' ? JSON.stringify(this.body) : null;
    }

    /** Parses response based on content type */
    async parseResponse(response) {
        const contentType = response.headers.get("Content-Type") || "";
        if (contentType.includes("application/json")) {
            this.json = await response.json();
        }
        this.content = await response.text();
    }
}
